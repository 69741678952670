import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import logo from "../../Images/HeaderLogo.png";
import logoIcon from "../../Images/HeaderLogoIcon.png";

function ChartSearch({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    onSearch(newSearchTerm); // Pass the search term to the parent component for filtering
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <img
        src={logoIcon}
        alt="Spc Chart Logo"
        width={50}
        height={40}
        style={{ height: '40px' }} // Ensures the image height matches the TextField height
      />
      <TextField
        label="Search charts by name or tags"
        variant="outlined"
        size="small"
        sx={{
          marginLeft: 1, // Adds some space between the image and the text field
          height: '38px', // Ensures the TextField height matches the image height
        }}
        value={searchTerm}
        onChange={handleSearch}
      />
    </Box>
  );
}

export default ChartSearch;
