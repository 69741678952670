import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Fab,
  Grid,
  Menu,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Spreadsheet, {
  EmptySelection,
  PointRange,
  RangeSelection,
} from "react-spreadsheet";
import {
  addNewRow,
  calculateAndCreateXBarTableData,
  createXBarDisplayHeaders,
  createSpreadSheetTableData,
  removeRow,
  isEmptyRow,
} from "./xBarTableHelpFunction";
import { useDebounce } from "../../Hooks/debounceHooks";

const XBarTable = (props) => {
  const {
    chartProperties,
    onChangeTable,
    incomingData,
    columnHeaders,
    subgroupSize,
    role,
    apiCall,
    setApiCall,
  } = props;

  const mobileView = window.innerWidth;

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [displayHeaders, setDisplayHeaders] = useState([]);
  const [selected, setSelected] = useState(new EmptySelection());
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // Create column headers
    const createdDisplayHeaders = createXBarDisplayHeaders(
      columnHeaders,
      subgroupSize,
      parseFloat(chartProperties.lowerSpecLimitValue),
      parseFloat(chartProperties.upperSpecLimitValue),
      role
    );
    setDisplayHeaders(createdDisplayHeaders);
  }, [columnHeaders, chartProperties, subgroupSize, role]);

  useEffect(() => {
    if (incomingData.length === 0) {
      let emptyRow = ["", "", "", "", ""];
      for (let cell = 0; cell < subgroupSize; cell++) {
        emptyRow.push("");
      }
      let emptyRows = [];
      for (let row = 0; row < 50; row++) {
        emptyRows.push(emptyRow);
      }
      setData(emptyRows);
    } else {
      const dataFromDb = incomingData.map((row) => {
        const sliceIndex = 4 + subgroupSize;
        let newRow = [];
        for (let cell = 0; cell < sliceIndex; cell++) {
          newRow.push(
            row[cell] === null || row[cell] === "undefined" ? "" : row[cell]
          );
        }
        return newRow;
      });
      setData(dataFromDb);
    }
  }, [incomingData, subgroupSize]);

  useEffect(() => {
    // Set table data for spread sheet
    const editedColumnHeaders = columnHeaders.map((column, index) => {
      let newColumn = "";
      if (index === 0) {
        newColumn = column + "(Appears on Chart)";
      } else {
        newColumn = column;
      }
      return newColumn;
    });

    const userEnteredData = data.map((row) => row?.slice(0, 3 + subgroupSize));
    const calculatedTableData = calculateAndCreateXBarTableData(
      userEnteredData,
      subgroupSize,
      parseFloat(chartProperties.lockLimit),
      parseFloat(chartProperties.lowerSpecLimitValue),
      parseFloat(chartProperties.upperSpecLimitValue)
    );
    const tableDataForSpreadSheet = createSpreadSheetTableData(
      calculatedTableData,
      subgroupSize,
      editedColumnHeaders,
      displayHeaders
    );
    setTableData(tableDataForSpreadSheet);
  }, [data, displayHeaders, chartProperties, columnHeaders, subgroupSize]);

  // Handle mouse click
  const [rightClickMenuOpen, setRightClickMenuOpen] = useState(false);
  const handleMouseClick = (e) => {
    e.preventDefault();
    if (e.button === 2 && activeCellValues.row !== undefined) {
      setRightClickMenuOpen(true);
    }
  };

  const addRowAbove = () => {
    const place = "above";
    const dataWithNewRow = addNewRow(
      data,
      subgroupSize,
      activeCellValues.row,
      place
    );
    const newData = [...dataWithNewRow];
    onChangeTable(newData);
    setRightClickMenuOpen(false);
  };
  const addRowBelow = () => {
    const place = "below";
    const dataWithNewRow = addNewRow(
      data,
      subgroupSize,
      activeCellValues.row,
      place
    );
    const newData = [...dataWithNewRow];
    onChangeTable(newData);
    setRightClickMenuOpen(false);
  };
  const deleteRow = () => {
    const newDataAfterDeleteRow = removeRow(data, activeCellValues.row);
    const newData = [...newDataAfterDeleteRow];
    onChangeTable(newData);
    setRightClickMenuOpen(false);
  };

  // Active cell function
  const [activeCellValues, setActiveCellValues] = useState({});

  const activeCell = (e) => {
    setActiveCellValues(e);
  };

  // Keyboard function
  const keyBoardFunction = (key) => {
    setApiCall(false);
    if (key.code === "ArrowDown") {
      const pointRange = new PointRange(
        { row: activeCellValues.row + 1, column: activeCellValues.column },
        { row: activeCellValues.row + 1, column: activeCellValues.column }
      );
      setSelected(new RangeSelection(pointRange));
    } else if (key.code === "ArrowRight") {
      const pointRange = new PointRange(
        { row: activeCellValues.row, column: activeCellValues.column + 1 },
        { row: activeCellValues.row, column: activeCellValues.column + 1 }
      );
      setSelected(new RangeSelection(pointRange));
    } else if (key.code === "ArrowLeft") {
      const pointRange = new PointRange(
        { row: activeCellValues.row, column: activeCellValues.column - 1 },
        { row: activeCellValues.row, column: activeCellValues.column - 1 }
      );
      setSelected(new RangeSelection(pointRange));
    } else if (key.code === "ArrowUp") {
      const pointRange = new PointRange(
        { row: activeCellValues.row - 1, column: activeCellValues.column },
        { row: activeCellValues.row - 1, column: activeCellValues.column }
      );
      setSelected(new RangeSelection(pointRange));
    }
  };

  // Get change data
  const removeLastEmptyRow = (dataArray) => {
    if (
      dataArray.length === 0 ||
      !isEmptyRow(dataArray[dataArray.length - 1])
    ) {
      return dataArray;
    } else {
      dataArray.pop(); // Remove the last row
      return removeLastEmptyRow(dataArray); // Recursively call with updated array
    }
  };

  const [changedData, setChangedData] = useState([]);

  const changeOnTable = (newData) => {
    const changedData = [...newData];
    let userEnteredData = changedData.map((row, index) => {
      let rowValues = [];
      for (let cell = 0; cell < 5 + subgroupSize; cell++) {
        if (cell !== subgroupSize + 2 && cell !== subgroupSize + 3) {
          rowValues.push(
            row[cell]?.value === undefined ? "" : row[cell]?.value
          );
        }
      }
      return rowValues;
    });

    if (!apiCall) {
      setChangedData(removeLastEmptyRow(userEnteredData));
    }
  };

  const debounceChange = useDebounce(changedData);
  useEffect(() => {
    onChangeTable(debounceChange);
  }, [debounceChange]);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        onClick={handleMouseClick}
        onContextMenu={handleMouseClick}
      >
        <div
          style={{
            width: "90%",
            maxHeight: "800px", // Adjust the maximum height as needed
            overflow: "auto",
          }}
        >
          {rightClickMenuOpen && (
            <Dialog
              open={rightClickMenuOpen}
              onClose={() => setRightClickMenuOpen(false)}
            >
              <DialogContent>
                <DialogContentText>Select an action</DialogContentText>
                <br />
                <Button onClick={addRowAbove}>Add Row Above</Button>
                <br />
                <Button onClick={addRowBelow}>Add Row Below</Button>
                <br />
                <Button onClick={deleteRow}>Delete Selected Row</Button>
                <br />
                <Button
                  onClick={() => setRightClickMenuOpen(false)}
                  color="primary"
                >
                  Close
                </Button>
                <br />
              </DialogContent>
            </Dialog>
          )}
          <Spreadsheet
            columnLabels={displayHeaders}
            data={tableData}
            onActivate={(e) => activeCell(e)}
            onKeyDown={(key) => keyBoardFunction(key)}
            onChange={(newData) => changeOnTable(newData)}
            selected={selected}
          />
          {mobileView < 600 && (
            <Box>
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                sx={{
                  position: "fixed",
                  bottom: 16,
                  right: 16,
                }}
                onClick={handleClick}
              >
                <KeyboardArrowDownIcon />
              </Fab>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    addRowAbove();
                    handleClose();
                  }}
                >
                  Add Row Above
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    addRowBelow();
                    handleClose();
                  }}
                >
                  Add Row Below
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    deleteRow();
                  }}
                >
                  Delete Selected Row
                </MenuItem>
              </Menu>
            </Box>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default XBarTable;
