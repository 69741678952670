import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Avatar,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import PeopleIcon from "@mui/icons-material/People";
import Groups2Icon from "@mui/icons-material/Groups2";
import NavigationChartSearch from "../NavigationChartSearch";
import logoIcon from "../../Images/HeaderLogoIcon.png";

function MainNavigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const isXmr = location.pathname.startsWith("/i");
  const isXBar = location.pathname.startsWith("/s");

  const isMobile = useMediaQuery("(max-width:600px)");
  const [anchorEl, setAnchorEl] = useState(null);

  const userData = localStorage.getItem("userData");
  const parsedUserData = userData ? JSON.parse(userData) : null;
  const userName = parsedUserData
    ? `${parsedUserData.firstName} ${parsedUserData.lastName}`
    : "Guest User";

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    handleMenuClose();
    navigate(path);
  };

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const getInitials = (name) => {
    return name
      .split(" ")
      .map((part) => part.charAt(0))
      .join("")
      .toUpperCase();
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "white",
        paddingLeft: { md: 10 },
        paddingRight: { md: 10 },
      }}
    >
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {isXmr || isXBar ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={logoIcon}
                alt="Spc Chart Logo"
                width={65.4}
                height={44.5}
                onClick={() => navigate("/dashboard")}
                style={{ cursor: "pointer" }}
              />
              <NavigationChartSearch />
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={logoIcon}
                alt="Spc Chart Logo"
                width={65.4}
                height={44.5}
                onClick={() => navigate("/dashboard")}
                style={{ cursor: "pointer" }}
              />
            </Box>
          )}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {!isMobile && (
              <Typography variant="body1" color="textPrimary">
                {userName}
              </Typography>
            )}
            <Avatar onClick={handleMenuOpen} sx={{ cursor: "pointer" }}>
              {getInitials(userName)}
            </Avatar>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleNavigation("/dashboard")}>
                <DashboardIcon sx={{ fontSize: 20, color: "#1976d2" }} />
                <Typography color="#1976d2">My Charts</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleNavigation("/account")}>
                <SettingsIcon sx={{ fontSize: 20, color: "#1976d2" }} />
                <Typography color="#1976d2">Account</Typography>
              </MenuItem>
              {parsedUserData?.email === "dm1217@gmail.com" && (
                <MenuItem onClick={() => handleNavigation("/users")}>
                  <PeopleIcon sx={{ fontSize: 20, color: "#1976d2" }} />
                  <Typography color="#1976d2">Users</Typography>
                </MenuItem>
              )}
              {parsedUserData?.email === "dm1217@gmail.com" && (
                <MenuItem onClick={() => handleNavigation("/organization")}>
                  <Groups2Icon sx={{ fontSize: 20, color: "#1976d2" }} />
                  <Typography color="#1976d2">Organization</Typography>
                </MenuItem>
              )}
              <MenuItem onClick={logout}>
                <LogoutIcon sx={{ fontSize: 20, color: "#1976d2" }} />
                <Typography color="#1976d2">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default MainNavigation;
