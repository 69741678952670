import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import runCharts from "../../Images/Run_Charts.png";
import histogram from "../../Images/Histograms.png";
import exportData from "../../Images/Export_Data.png";
import linkSharing from "../../Images/Link _Sharing.png";
import secureAccess from "../../Images/Secure_Access.png";
import controlCharts from "../../Images/Control_Charts.png";
import knowledgeBase from "../../Images/Knowledge_Base.png";
import { createUser, verifyUser } from "../../service.global";
import chartNotations from "../../Images/Chart_Notations.png";
import capabilityPlots from "../../Images/Capability_Plots.png";

function RegisterComponent() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [step, setStep] = useState(1);
  const [verificationCode, setVerificationCode] = useState("");

  const userInLocalStorage = localStorage.getItem("userData");

  useEffect(() => {
    if (userInLocalStorage !== null) {
      navigate("/dashboard");
    }
  }, [navigate, userInLocalStorage]);

  // Register user
  const getEmail = (e) => {
    setEmail(e.target.value);
  };
  const getPassword = (e) => {
    setPassword(e.target.value);
  };
  const getFirstName = (e) => {
    setFirstName(e.target.value);
  };
  const getLastName = (e) => {
    setLastName(e.target.value);
  };
  const handleRegisterUser = () => {
    const newUserData = {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      role: "User",
    };
    createUser(newUserData)
      .then((res) => {
        if (res) {
          setStep(2);
        }
      })
      .catch((err) => {
        console.log(err);
        alert("User already exist");
        //show user already exist toast
      });
  };

  const handleVerifyCode = () => {
    verifyUser(email, verificationCode).then((res) => {
      if (res) {
        navigate("/login");
      }
    });
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{
        bgcolor: "#FFFFF",
        paddingLeft: { xs: 0, md: 20 },
        paddingRight: { xs: 0, md: 20 },
        paddingBottom: { xs: 1, md: 5 },
        paddingTop: 5,
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          marginLeft: { xs: 2, md: 10 },
          marginRight: { xs: 2, md: 10 },
          overflow: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Grid container spacing={2}>
              {featuresContent.map((content, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={index}
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <Card
                    style={{
                      display: "flex",
                      margin: 1,
                      borderRadius: 0,
                      boxShadow: "0 0 0 0",
                      height: 108,
                      border: "solid 2px #E9E9E9",
                    }}
                  >
                    <CardMedia
                      component="img"
                      style={{
                        width: 50,
                        height: 50,
                        paddingTop: 20,
                        paddingLeft: 8,
                      }}
                      image={content.image}
                      alt={content.title}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <CardContent style={{ flex: "1 0 auto" }}>
                        <Typography
                          component="div"
                          variant="h6"
                          style={{ fontSize: "0.875rem", fontWeight: 600 }}
                        >
                          {content.title}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          sx={{
                            fontSize: "0.85rem",
                            color: "text.secondary",
                            fontFamily: "'Core Sans', sans-serif",
                          }}
                        >
                          {content.content}
                        </Typography>
                      </CardContent>
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item md={6} xs={12} textAlign="center">
            <Paper
              elevation={3}
              sx={{
                padding: 4,
                paddingLeft: 4,
                paddingRight: 4,
                borderRadius: 0,
                display: "flex",
                boxShadow: "0 0 0 0",
                flexDirection: "column",
                justifyContent: "space-between",
                mx: "auto",
                background: "#00A3E8",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    sx={{ textAlign: "center", color: "#FFFFFF" }}
                    variant="h4"
                  >
                    Get Started Now!
                  </Typography>
                </Grid>
                {step === 1 && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="email"
                        sx={{
                          background: "white",
                        }}
                        variant="outlined"
                        label="Email Address"
                        onChange={(e) => getEmail(e)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="password"
                        sx={{
                          background: "white",
                        }}
                        variant="outlined"
                        label="Password"
                        type="password"
                        onChange={(e) => getPassword(e)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="firstName"
                        sx={{
                          background: "white",
                        }}
                        variant="outlined"
                        label="First Name"
                        onChange={(e) => getFirstName(e)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="lastName"
                        sx={{
                          background: "white",
                        }}
                        variant="outlined"
                        label="Last Name"
                        onChange={(e) => getLastName(e)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        sx={{
                          backgroundColor: "#FFF000",
                          color: "#000000", // Corrected color code for black
                          borderRadius: 1,
                          fontWeight: 600,
                          fontSize: 20,
                          width: "100%",
                          "&:hover": {
                            backgroundColor: "#E6DB00", // Slightly darker yellow for hover effect
                          },
                        }}
                        variant="contained"
                        onClick={handleRegisterUser}
                      >
                        Sign Up
                      </Button>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "#FFFFFF",
                          fontSize: "12px",
                        }}
                        variant="p"
                      >
                        By signing up, you agree to our communications and usage
                        terms.
                      </Typography>
                    </Grid>
                  </>
                )}
                {step === 2 && (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        variant="p"
                        sx={{ fontSize: 15, color: "#586771" }}
                      >
                        Please check your email inbox for your Verification Code
                        and paste it here:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="verificationCode"
                        variant="outlined"
                        label="Verification Code"
                        onChange={(e) => setVerificationCode(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        sx={{
                          backgroundColor: "#FFF000",
                          borderRadius: 1,
                          width: "100%",
                          "&:hover": {
                            backgroundColor: "#E6DB00", // Slightly darker yellow for hover effect
                          },
                        }}
                        variant="contained"
                        onClick={handleVerifyCode}
                      >
                        Verify
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default RegisterComponent;

export const featuresContent = [
  {
    image: controlCharts,
    title: "Control Charts",
    content:
      "Integrate web-based SPC into your workflow to capture insights from data",
  },
  // {
  //   image: runCharts,
  //   title: "Run Charts",
  //   content: "View simple time-series trending without control limits",
  // },

  {
    image: knowledgeBase,
    title: "Knowledge Base",
    content:
      "Explore simple tutorials on SPC and continual improvement strategies",
  },

  {
    image: linkSharing,
    title: "Link Sharing",
    content: "Enhance collaboration by sharing viewing access to charts",
  },
  {
    image: secureAccess,
    title: "Secure Access",
    content:
      "Set up chart access on a platform optimized for data privacy and security",
  },
  {
    image: chartNotations,
    title: "Chart Notations",
    content:
      "Enter data insights that display when points are hovered or clicked.",
  },

  {
    image: exportData,
    title: "Export Data",
    content:
      "Save data for archiving or transferring to other statistics applications",
  },
  {
    image: histogram,
    title: "Histograms",
    content:
      "Analyze the distribution of your data with custom sensitivity toggling",
  },
  {
    image: capabilityPlots,
    title: "Capability Plots",
    content: "Time-series plots of capability and performance indices",
  },
];
