import axios from "axios";

const http = axios.create({
  baseURL: "https://spc-d01360db3ce1.herokuapp.com/api/v1/",
  headers: {
    "Content-type": "application/json",
  },
});

export default http;
