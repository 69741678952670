import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Fab,
  Grid,
  Menu,
  MenuItem,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useEffect, useState } from "react";
import Spreadsheet, {
  EmptySelection,
  PointRange,
  RangeSelection,
} from "react-spreadsheet";
import {
  addNewRow,
  calculateAndCreateTableData,
  createDisplayHeaders,
  createTableData,
  isEmptyRow,
  removeRow,
} from "./xmrTableHelpFunctions";
import { useDebounce } from "../../Hooks/debounceHooks";

const XmrTable = (props) => {
  const {
    onChangeTable,
    incomingData,
    chartProperties,
    columnHeaders,
    role,
    apiCall,
    setApiCall,
  } = props;

  const mobileView = window.innerWidth;

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [displayHeaders, setDisplayHeaders] = useState([]);
  const [selected, setSelected] = useState(new EmptySelection());
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // Create column headers to display
    const displayHeaders1 = createDisplayHeaders(
      columnHeaders,
      parseFloat(chartProperties.lowerSpecLimitValue),
      parseFloat(chartProperties.upperSpecLimitValue),
      role
    );
    setDisplayHeaders(displayHeaders1);
  }, [chartProperties, columnHeaders, role]);

  useEffect(() => {
    if (incomingData.length === 0) {
      let emptyRows = [];
      for (let row = 0; row < 50; row++) {
        emptyRows.push(["", "", "", "", ""]);
      }
      setData(emptyRows);
    } else {
      const dataFromDb = incomingData.map((row, index) => {
        let newRow = [];
        for (let cell = 0; cell < 4; cell++) {
          newRow.push(
            row[cell] === null || row[cell] === "undefined" ? "" : row[cell]
          );
        }
        return newRow;
      });
      setData(dataFromDb);
    }
  }, [incomingData]);

  useEffect(() => {
    // Set table data for table
    const editedColumnHeaders = columnHeaders.map((column, index) => {
      let newColumn = "";
      if (index === 0) {
        newColumn = column + "(Appears on Chart)";
      } else {
        newColumn = column;
      }
      return newColumn;
    });
    const userEnteredData = data.map((row) => row.slice(0, 5));
    const calculatedTableData = calculateAndCreateTableData(
      parseFloat(chartProperties.lockLimit),
      parseFloat(chartProperties.lowerSpecLimitValue),
      parseFloat(chartProperties.upperSpecLimitValue),
      userEnteredData
    );

    const tableDataForSpreadSheet = createTableData(
      calculatedTableData,
      editedColumnHeaders,
      displayHeaders,
      parseFloat(chartProperties.lockLimit)
    );
    setTableData(tableDataForSpreadSheet);
  }, [data, displayHeaders, chartProperties, columnHeaders]);

  // Handle Mouse Click
  const handleMouseClick = (e) => {
    e.preventDefault();
    if (e.button === 2 && activeCellValues.row !== undefined) {
      setRightClickMenuOpen(true);
    }
  };

  // Active cell functions
  const [activeCellValues, setActiveCellValues] = useState({});

  const activeCell = (e) => {
    setActiveCellValues(e);
  };

  // Keyboard Functions
  const keyBoardFunction = (key) => {
    setApiCall(false);
    if (key.code === "ArrowDown") {
      const pointRange = new PointRange(
        { row: activeCellValues.row + 1, column: activeCellValues.column },
        { row: activeCellValues.row + 1, column: activeCellValues.column }
      );
      setSelected(new RangeSelection(pointRange));
    } else if (key.code === "ArrowRight") {
      const pointRange = new PointRange(
        { row: activeCellValues.row, column: activeCellValues.column + 1 },
        { row: activeCellValues.row, column: activeCellValues.column + 1 }
      );
      setSelected(new RangeSelection(pointRange));
    } else if (key.code === "ArrowLeft") {
      const pointRange = new PointRange(
        { row: activeCellValues.row, column: activeCellValues.column - 1 },
        { row: activeCellValues.row, column: activeCellValues.column - 1 }
      );
      setSelected(new RangeSelection(pointRange));
    } else if (key.code === "ArrowUp") {
      const pointRange = new PointRange(
        { row: activeCellValues.row - 1, column: activeCellValues.column },
        { row: activeCellValues.row - 1, column: activeCellValues.column }
      );
      setSelected(new RangeSelection(pointRange));
    }
  };

  // Get change data
  const removeLastEmptyRow = (dataArray) => {
    if (
      dataArray.length === 0 ||
      !isEmptyRow(dataArray[dataArray.length - 1])
    ) {
      return dataArray;
    } else {
      dataArray.pop(); // Remove the last row
      return removeLastEmptyRow(dataArray); // Recursively call with updated array
    }
  };

  const [changedData, setChangedData] = useState([]);
  const changeOnTable = (newData) => {
    let userEnteredData = newData.map((row) => {
      let rowValues = [];
      for (let cell = 0; cell < 5; cell++) {
        if (cell !== 3) {
          rowValues.push(
            row[cell]?.value === undefined ? "" : row[cell]?.value
          );
        }
      }
      return rowValues;
    });
    if (!apiCall) {
      setChangedData(removeLastEmptyRow(userEnteredData));
    }
  };
  const debounceChange = useDebounce(changedData);

  useEffect(() => {
    onChangeTable(debounceChange);
  }, [debounceChange]);

  // Right click menu
  const [rightClickMenuOpen, setRightClickMenuOpen] = useState(false);

  const addRowAbove = () => {
    const place = "above";
    const dataWithNewRow = addNewRow(data, activeCellValues.row, place);
    const newData = [...dataWithNewRow];
    onChangeTable(newData);
    setRightClickMenuOpen(false);
  };
  const addRowBelow = () => {
    const place = "below";
    const dataWithNewRow = addNewRow(data, activeCellValues.row, place);
    const newData = [...dataWithNewRow];
    onChangeTable(newData);
    setRightClickMenuOpen(false);
  };
  const deleteRow = () => {
    const newDataAfterDeleteRow = removeRow(data, activeCellValues.row);
    const newData = [...newDataAfterDeleteRow];
    onChangeTable(newData);
    setRightClickMenuOpen(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        onClick={handleMouseClick}
        onContextMenu={handleMouseClick}
      >
        <div
          style={{
            width: "90%",
            maxHeight: "800px", // Adjust the maximum height as needed
            overflow: "auto",
          }}
        >
          {rightClickMenuOpen && (
            <Dialog
              open={rightClickMenuOpen}
              onClose={() => setRightClickMenuOpen(false)}
            >
              <DialogContent>
                <DialogContentText>Select an action</DialogContentText>
                <br />
                <Button onClick={addRowAbove}>Add Row Above</Button>
                <br />
                <Button onClick={addRowBelow}>Add Row Below</Button>
                <br />
                <Button onClick={deleteRow}>Delete Selected Row</Button>
                <br />
                <Button
                  onClick={() => setRightClickMenuOpen(false)}
                  color="primary"
                >
                  Close
                </Button>
                <br />
              </DialogContent>
            </Dialog>
          )}
          <Spreadsheet
            columnLabels={displayHeaders}
            data={tableData}
            onActivate={(e) => activeCell(e)}
            onChange={(newData) => changeOnTable(newData)}
            onKeyDown={(key) => keyBoardFunction(key)}
            selected={selected}
          />
          {mobileView < 600 && (
            <Box>
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                sx={{
                  position: "fixed",
                  bottom: 16,
                  right: 16,
                }}
                onClick={handleClick}
              >
                <KeyboardArrowDownIcon />
              </Fab>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    addRowAbove();
                    handleClose();
                  }}
                >
                  Add Row Above
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    addRowBelow();
                    handleClose();
                  }}
                >
                  Add Row Below
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    deleteRow();
                  }}
                >
                  Delete Selected Row
                </MenuItem>
              </Menu>
            </Box>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default XmrTable;
