import { Radio } from "@mui/material";
import {
  addEmptyValues,
  calculateAverage,
  calculateAvgCl,
  calculateAvgCpk,
  calculateAvgLcl,
  calculateAvgPpk,
  calculateAvgUcl,
  calculateCp,
  calculateCpk,
  calculateCpl,
  calculateCpu,
  calculateCumulAvgRange,
  calculateCumulEstSd,
  calculateCumulGrandAvg,
  calculatePp,
  calculatePpk,
  calculatePpl,
  calculatePpu,
  calculateRange,
  calculateRngCl,
  calculateRngLcl,
  calculateRngUcl,
  calculateSampleStdDev,
} from "./XBarCalculationNew";

// Create display headers
export const checkValid = (value) => {
  if (!isNaN(parseFloat(value))) {
    return true;
  } else {
    return false;
  }
};

export const validValue = (value) => {
  if (isNaN(value)) {
    return false;
  } else if (value === 0) {
    return false;
  } else {
    return true;
  }
};

export const createXBarDisplayHeaders = (
  headers,
  subgroupSize,
  lsl,
  usl,
  role
) => {
  let displayHeaders = [];
  if (role === "Admin") {
    for (let headerIndex = 0; headerIndex < 5 + subgroupSize; headerIndex++) {
      if (headerIndex === 0) {
        displayHeaders.push(headers[headerIndex] + "(Appears on Chart)");
      } else {
        displayHeaders.push(headers[headerIndex]);
      }
    }
  } else {
    for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
      if (headerIndex === 0) {
        displayHeaders.push(headers[headerIndex] + "(Appears on Chart)");
      } else if (
        headerIndex === 15 + subgroupSize ||
        headerIndex === 19 + subgroupSize
      ) {
        if (validValue(lsl)) {
          displayHeaders.push(headers[headerIndex]);
        }
      } else if (
        headerIndex === 16 + subgroupSize ||
        headerIndex === 20 + subgroupSize
      ) {
        if (validValue(usl)) {
          displayHeaders.push(headers[headerIndex]);
        }
      } else if (
        headerIndex === 17 + subgroupSize ||
        headerIndex === 18 + subgroupSize ||
        headerIndex === 21 + subgroupSize ||
        headerIndex === 22 + subgroupSize
      ) {
        if (validValue(lsl) || validValue(usl)) {
          displayHeaders.push(headers[headerIndex]);
        }
      } else if (
        headerIndex === 23 + subgroupSize ||
        headerIndex === 24 + subgroupSize
      ) {
        if (validValue(lsl) && validValue(usl)) {
          displayHeaders.push(headers[headerIndex]);
        }
      } else {
        displayHeaders.push(headers[headerIndex]);
      }
    }
  }
  return displayHeaders;
};

export const createXBarDisplayHeadersForEdit = (
  headers,
  subgroupSize,
  lsl,
  usl,
  role
) => {
  let displayHeaders = [];
  if (role === "Admin") {
    for (let headerIndex = 0; headerIndex < 5 + subgroupSize; headerIndex++) {
      if (headerIndex === 0) {
        displayHeaders.push(headers[headerIndex]);
      } else {
        displayHeaders.push(headers[headerIndex]);
      }
    }
  } else {
    for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
      if (headerIndex === 0) {
        displayHeaders.push(headers[headerIndex]);
      } else if (
        headerIndex === 15 + subgroupSize ||
        headerIndex === 19 + subgroupSize
      ) {
        if (validValue(lsl)) {
          displayHeaders.push(headers[headerIndex]);
        }
      } else if (
        headerIndex === 16 + subgroupSize ||
        headerIndex === 20 + subgroupSize
      ) {
        if (validValue(usl)) {
          displayHeaders.push(headers[headerIndex]);
        }
      } else if (
        headerIndex === 17 + subgroupSize ||
        headerIndex === 18 + subgroupSize ||
        headerIndex === 21 + subgroupSize ||
        headerIndex === 22 + subgroupSize
      ) {
        if (validValue(lsl) || validValue(usl)) {
          displayHeaders.push(headers[headerIndex]);
        }
      } else if (
        headerIndex === 23 + subgroupSize ||
        headerIndex === 24 + subgroupSize
      ) {
        if (validValue(lsl) && validValue(usl)) {
          displayHeaders.push(headers[headerIndex]);
        }
      } else {
        displayHeaders.push(headers[headerIndex]);
      }
    }
  }
  return displayHeaders;
};

// Calculate and crate table data
export const calculateAndCreateXBarTableData = (
  userEnteredData,
  subgroupSize,
  lockLimit,
  lsl,
  usl
) => {
  const userEnteredValues = getValuesWithoutNull(userEnteredData, subgroupSize);
  // Create calculated arrays
  const avg = calculateAverage(userEnteredValues, subgroupSize);
  const avgArray = addEmptyValues(avg, userEnteredData, subgroupSize);

  const rng = calculateRange(userEnteredValues);
  const rngArray = addEmptyValues(rng, userEnteredData, subgroupSize);

  const cumulGrand = calculateCumulGrandAvg(userEnteredValues, subgroupSize);
  const cumulGrandAvgArray = addEmptyValues(
    cumulGrand,
    userEnteredData,
    subgroupSize
  );

  const cumulAvgRange = calculateCumulAvgRange(userEnteredValues, subgroupSize);
  const cumulAvgRangeArray = addEmptyValues(
    cumulAvgRange,
    userEnteredData,
    subgroupSize
  );

  const cumulAvgEstStdDev = calculateCumulEstSd(
    userEnteredValues,
    subgroupSize
  );
  const cumulAvgEstStdDevArray = addEmptyValues(
    cumulAvgEstStdDev,
    userEnteredData,
    subgroupSize
  );

  const sampleStdDev = calculateSampleStdDev(userEnteredValues, subgroupSize);
  const sampleStdDevArray = addEmptyValues(
    sampleStdDev,
    userEnteredData,
    subgroupSize
  );

  const avgUcl = calculateAvgUcl(userEnteredValues, subgroupSize, lockLimit);
  const avgUclArray = addEmptyValues(avgUcl, userEnteredData, subgroupSize);

  const avgCl = calculateAvgCl(userEnteredValues, subgroupSize, lockLimit);
  const avgClArray = addEmptyValues(avgCl, userEnteredData, subgroupSize);

  const avgLcl = calculateAvgLcl(userEnteredValues, subgroupSize, lockLimit);
  const avgLclArray = addEmptyValues(avgLcl, userEnteredData, subgroupSize);

  const rngUcl = calculateRngUcl(userEnteredValues, subgroupSize, lockLimit);
  const rngUclArray = addEmptyValues(rngUcl, userEnteredData, subgroupSize);

  const rngCl = calculateRngCl(userEnteredValues, subgroupSize, lockLimit);
  const rngClArray = addEmptyValues(rngCl, userEnteredData, subgroupSize);

  const rngLcl = calculateRngLcl(userEnteredValues, subgroupSize, lockLimit);
  const rngLclArray = addEmptyValues(rngLcl, userEnteredData, subgroupSize);

  const cpl = calculateCpl(userEnteredValues, subgroupSize, lsl);
  const cplArray = addEmptyValues(cpl, userEnteredData, subgroupSize);

  const cpu = calculateCpu(userEnteredValues, subgroupSize, usl);
  const cpuArray = addEmptyValues(cpu, userEnteredData, subgroupSize);

  const cpk = calculateCpk(userEnteredValues, subgroupSize, usl, lsl);
  const cpkArray = addEmptyValues(cpk, userEnteredData, subgroupSize);

  const avgCpk = calculateAvgCpk(userEnteredValues, subgroupSize, usl, lsl);
  const avgCpkArray = addEmptyValues(avgCpk, userEnteredData, subgroupSize);

  const ppl = calculatePpl(userEnteredValues, subgroupSize, lsl);
  const pplArray = addEmptyValues(ppl, userEnteredData, subgroupSize);

  const ppu = calculatePpu(userEnteredValues, subgroupSize, usl);
  const ppuArray = addEmptyValues(ppu, userEnteredData, subgroupSize);

  const ppk = calculatePpk(userEnteredValues, subgroupSize, usl, lsl);
  const ppkArray = addEmptyValues(ppk, userEnteredData, subgroupSize);

  const avgPpk = calculateAvgPpk(userEnteredValues, subgroupSize, usl, lsl);
  const avgPpkArray = addEmptyValues(avgPpk, userEnteredData, subgroupSize);

  const cp = calculateCp(userEnteredValues, subgroupSize, usl, lsl);
  const cpArray = addEmptyValues(cp, userEnteredData, subgroupSize);

  const pp = calculatePp(userEnteredValues, subgroupSize, usl, lsl);
  const ppArray = addEmptyValues(pp, userEnteredData, subgroupSize);

  // Map with table Array
  let userEditedTableData = [];
  for (let row = 0; row < userEnteredData.length; row++) {
    const values = userEnteredData[row]?.slice(2, subgroupSize + 2);
    userEditedTableData.push([
      userEnteredData[row][0],
      userEnteredData[row][1],
      ...values,
      isNaN(avgArray[row]) ? "" : avgArray[row],
      isNaN(rngArray[row]) ? "" : rngArray[row],
      userEnteredData[row][subgroupSize + 2],
      isNaN(cumulGrandAvgArray[row]) ? "" : cumulGrandAvgArray[row],
      isNaN(cumulAvgRangeArray[row]) ? "" : cumulAvgRangeArray[row],
      isNaN(cumulAvgEstStdDevArray[row]) ? "" : cumulAvgEstStdDevArray[row],
      isNaN(sampleStdDevArray[row]) ? "" : sampleStdDevArray[row],
      isNaN(avgUclArray[row]) ? "" : avgUclArray[row],
      isNaN(avgClArray[row]) ? "" : avgClArray[row],
      isNaN(avgLclArray[row]) ? "" : avgLclArray[row],
      isNaN(rngUclArray[row]) ? "" : rngUclArray[row],
      isNaN(rngClArray[row]) ? "" : rngClArray[row],
      isNaN(rngLclArray[row]) ? "" : rngLclArray[row],
      isNaN(cplArray[row]) ? "" : cplArray[row],
      isNaN(cpuArray[row]) ? "" : cpuArray[row],
      isNaN(cpkArray[row]) ? "" : cpkArray[row],
      isNaN(avgCpkArray[row]) ? "" : avgCpkArray[row],
      isNaN(pplArray[row]) ? "" : pplArray[row],
      isNaN(ppuArray[row]) ? "" : ppuArray[row],
      isNaN(ppkArray[row]) ? "" : ppkArray[row],
      isNaN(avgPpkArray[row]) ? "" : avgPpkArray[row],
      isNaN(cpArray[row]) ? "" : cpArray[row],
      isNaN(ppArray[row]) ? "" : ppArray[row],
    ]);
  }
  return userEditedTableData;
};

export const getValuesWithoutNull = (userEnteredData, subgroupSize) => {
  const valuesArray = [];
  for (let row = 0; row < userEnteredData.length; row++) {
    let valueRow = [];
    let invalidRow = false;
    for (let subValue = 0; subValue < subgroupSize; subValue++) {
      let x = parseFloat(userEnteredData[row][subValue + 2]);
      if (!isNaN(x) && x !== null) {
        valueRow.push(x);
      } else {
        invalidRow = true;
      }
    }
    if (!invalidRow) {
      valuesArray.push(valueRow);
    }
  }
  return valuesArray;
};

// Create data array for table
export const createSpreadSheetTableData = (
  data,
  subgroupSize,
  columnHeaders,
  displayHeaders
) => {
  let withoutEmptyRow = [];
  const removeLastEmptyRow = (dataArray) => {
    if (
      dataArray.length === 0 ||
      !isEmptyRow(dataArray[dataArray.length - 1])
    ) {
      return dataArray;
    } else {
      dataArray.pop();
      return removeLastEmptyRow(dataArray);
    }
  };
  if (data.length !== 0) {
    withoutEmptyRow = removeLastEmptyRow([...data]);
  }
  const tableData = withoutEmptyRow.map((row) => {
    let rowData = [];
    for (let cell = 0; cell < displayHeaders.length; cell++) {
      if (cell < 2 + subgroupSize || cell === 4 + subgroupSize) {
        rowData.push({
          value: row[columnHeaders.indexOf(displayHeaders[cell])],
        });
      } else {
        rowData.push({
          value: row[columnHeaders.indexOf(displayHeaders[cell])],
          readOnly: true,
        });
      }
    }
    return rowData;
  });
  let emptyRow = [
    {
      value: "",
    },
    {
      value: "",
    },
    {
      value: "",
    },
  ];
  for (let cell = 0; cell < subgroupSize; cell++) {
    emptyRow.push({
      value: "",
    });
  }
  for (let i = 0; i < 50; i++) {
    tableData.push(emptyRow);
  }
  return tableData;
};

// Check is empty row
export const isEmptyRow = (row) => {
  return row.every((element) => element === "");
};

// Add new row methods
export const addNewRow = (data, subgroupSize, selectedRow, place) => {
  let newRow = [false, "", "", ""];
  for (let cell = 0; cell < subgroupSize; cell++) {
    newRow.push("");
  }
  if (place === "end") {
    data.push(newRow);
  } else if (place === "above") {
    data.splice(selectedRow, 0, newRow);
  } else if (place === "below") {
    data.splice(selectedRow + 1, 0, newRow);
  }
  return data;
};

// Remove row in a table
export const removeRow = (data, selectedRow) => {
  data.splice(selectedRow, 1);
  return data;
};
