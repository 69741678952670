import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import spcTutorial from "../Images/spc.gif";
import runCharts from "../Images/Run_Charts.png";
import histogram from "../Images/Histograms.png";
import exportData from "../Images/Export_Data.png";
import linkSharing from "../Images/Link _Sharing.png";
import secureAccess from "../Images/Secure_Access.png";
import controlCharts from "../Images/Control_Charts.png";
import knowledgeBase from "../Images/Knowledge_Base.png";
import chartNotations from "../Images/Chart_Notations.png";
import capabilityPlots from "../Images/Capability_Plots.png";

import GuestUserNavigationLayout from "../Component/Layout/GuestUserNevigationLayout";
import RegisterComponent from "../Component/Register/RegisterComponent";

const Home = () => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={2} sx={{ bgcolor: "#ffffff", minHeight: "100vh" }}>
      <Grid item xs={12}>
        <GuestUserNavigationLayout />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          bgcolor: "rgba(244, 244, 244, 0.6)", // Adjust the alpha value as needed (0.8 for example)
        }}
      >
        <Grid
          container
          sx={{
            paddingLeft: { xs: 1, md: 20 },
            paddingRight: { xs: 1, md: 20 },
            paddingTop: 5,
            paddingBottom: 5,
          }}
        >
          {/* Left Section */}
          <Grid item xs={12} sm={12} md={6} textAlign="left" paddingBottom={15}>
            <Grid container spacing={5} sx={{ paddingRight: 5 }}>
              <Grid
                item
                xs={12}
                sx={{ marginTop: 10, marginLeft: { md: 10, sm: 3, xs: 3 } }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: {
                      xs: "24px", // Font size for extra-small screens
                      sm: "32px", // Font size for small screens
                      md: "40px", // Font size for medium screens
                    },
                    fontWeight: 550,
                  }}
                >
                  Drive process improvement with a simple data visualization
                  platform.
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ marginLeft: { md: 10, sm: 3, xs: 3 } }}>
                <Typography
                  variant="body1" // Use the appropriate variant for your text
                  sx={{
                    color: "#616363",
                    fontSize: {
                      xs: "16px", // Font size for extra-small screens
                      sm: "20px", // Font size for small screens
                      md: "25px", // Font size for medium screens
                    },
                  }}
                >
                  SPCcharts provides easy-to-use analytics 
                  solutions to improve workflows and empower 
                  organizations to make data-driven decisions.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                paddingBottom={2}
                sx={{ marginLeft: { md: 10, sm: 3, xs: 3 } }}
              >
                <Button
                  onClick={() => navigate("/register")}
                  variant="contained"
                  size="large"
                  sx={{
                    boxShadow:'0 0 0 0',
                    backgroundColor: "#3F79FF", // Bluish purple color
                    color: "#fff", // Text color
                    "&:hover": {
                      backgroundColor: "#285BD1", // Darker shade on hover
                    },
                  }}
                >
                  Get Started
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Right Section */}
          <Grid item xs={12} sm={12} md={6}>
            <img
              src={spcTutorial}
              style={{
                marginTop: 20,
                marginLeft: { xs: 1, md: 100 },
                maxWidth: "95%", // Ensure image responsiveness
              }}
              alt="Spc Chart Computer Display"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <RegisterComponent />
        {/* <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                textAlign: "center",
                fontSize: "28px",
                fontWeight: "bold",
                color: "#333",
              }}
            >
              App Features
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          {featuresContent.map((feature, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Card
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  margin: "16px", // Add margin for space around the card
                }}
              >
                <CardActionArea>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "left",
                      justifyContent: "left",
                      marginLeft: 10,
                      padding: 5,
                      height: "100px", // Set the height you want
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{ width: 80, height: 80 }}
                      image={feature.image}
                      alt="Feature Image"
                    />
                  </div>
                  <CardContent sx={{ textAlign: "left" }}>
                    <Typography gutterBottom variant="h5" component="div">
                      {feature.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {feature.content}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default Home;

export const featuresContent = [
  {
    image: controlCharts,
    title: "Control Charts",
    content: "Build charts for 'individuals' and sub grouped data",
  },
  // {
  //   image: runCharts,
  //   title: "Run Charts",
  //   content: "View simple time-series trending without control limits",
  // },
  {
    image: histogram,
    title: "Histograms",
    content: "Analyze the distribution of your process data",
  },
  {
    image: secureAccess,
    title: "Secure Access",
    content: "Set up password-protected access to charts on a secure network",
  },
  {
    image: chartNotations,
    title: "Chart Notations",
    content:
      "Enter notes viewable on charts when points are hovered or clicked",
  },
  {
    image: capabilityPlots,
    title: "Capability Plots",
    content: "Time-series plots of capability and performance indices",
  },
  {
    image: linkSharing,
    title: "Link Sharing",
    content: "Enhance collaboration by sharing viewing access to charts",
  },
  {
    image: exportData,
    title: "Export Data",
    content:
      "Save data for archiving or transferring to other statistics applications",
  },
  {
    image: knowledgeBase,
    title: "Knowledge Base",
    content:
      "Learn fundamentals of SPC through simple concepts and visualizations",
  },
];
