//import { Radio, Typography } from "@mui/material";
import {
  addEmptyValues,
  averageCPK,
  calculateAvgPpk,
  calculateCPLArray,
  calculateCPUArray,
  calculateCp,
  calculateCpkArray,
  calculateCumulAvg,
  calculateCumulativeAvgEstimatedStdDev,
  calculateCumulativeAvgMovingRange,
  calculateMRCLArray,
  calculateMRUCLArray,
  calculateMovingRange,
  calculatePp,
  calculatePpk,
  calculatePpl,
  calculatePpu,
  calculateXCL,
  calculateXLCL,
  calculateXUCLIndividualChart,
  sampleStdDev,
} from "./calculationsNew";

// Create display headers
export const checkValid = (value) => {
  if (!isNaN(parseFloat(value))) {
    return true;
  } else {
    return false;
  }
};

export const validCharacter = (value) => {
  if (isNaN(value)) {
    return false;
  } else if (value === 0) {
    return false;
  } else {
    return true;
  }
};
export const createDisplayHeaders = (headers, lsl, usl, role) => {
  let displayHeaders = [];
  if (role === "Admin") {
    for (let headerIndex = 0; headerIndex < 5; headerIndex++) {
      if (headerIndex === 0) {
        displayHeaders.push(headers[headerIndex] + "(Appears on Chart)");
      } else {
        displayHeaders.push(headers[headerIndex]);
      }
    }
  } else {
    for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
      if (headerIndex === 0) {
        displayHeaders.push(headers[headerIndex] + "(Appears on Chart)");
      } else if (headerIndex === 13 || headerIndex === 18) {
        if (validCharacter(lsl)) {
          displayHeaders.push(headers[headerIndex]);
        }
      } else if (headerIndex === 14 || headerIndex === 19) {
        if (validCharacter(usl)) {
          displayHeaders.push(headers[headerIndex]);
        }
      } else if (
        headerIndex === 15 ||
        headerIndex === 16 ||
        headerIndex === 20 ||
        headerIndex === 21
      ) {
        if (validCharacter(lsl) || validCharacter(usl)) {
          displayHeaders.push(headers[headerIndex]);
        }
      } else if (headerIndex === 22 || headerIndex === 23) {
        if (validCharacter(lsl) && validCharacter(usl)) {
          displayHeaders.push(headers[headerIndex]);
        }
      } else {
        displayHeaders.push(headers[headerIndex]);
      }
    }
  }
  return displayHeaders;
};

export const createDisplayHeadersForEdit = (headers, lsl, usl, role) => {
  let displayHeaders = [];
  if (role === "Admin") {
    for (let headerIndex = 0; headerIndex < 5; headerIndex++) {
      if (headerIndex === 0) {
        displayHeaders.push(headers[headerIndex]);
      } else {
        displayHeaders.push(headers[headerIndex]);
      }
    }
  } else {
    for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
      if (headerIndex === 0) {
        displayHeaders.push(headers[headerIndex]);
      } else if (headerIndex === 13 || headerIndex === 18) {
        if (validCharacter(lsl)) {
          displayHeaders.push(headers[headerIndex]);
        }
      } else if (headerIndex === 14 || headerIndex === 19) {
        if (validCharacter(usl)) {
          displayHeaders.push(headers[headerIndex]);
        }
      } else if (
        headerIndex === 15 ||
        headerIndex === 16 ||
        headerIndex === 20 ||
        headerIndex === 21
      ) {
        if (validCharacter(lsl) || validCharacter(usl)) {
          displayHeaders.push(headers[headerIndex]);
        }
      } else if (headerIndex === 22 || headerIndex === 23) {
        if (validCharacter(lsl) && validCharacter(usl)) {
          displayHeaders.push(headers[headerIndex]);
        }
      } else {
        displayHeaders.push(headers[headerIndex]);
      }
    }
  }
  return displayHeaders;
};

// Create table data array
export const calculateAndCreateTableData = (
  lockLimit,
  lsl,
  usl,
  userEnteredData
) => {
  const userEnteredValues = getValuesWithoutNull(userEnteredData);
  // Create calculated arrays
  const movingRange = calculateMovingRange(userEnteredValues);
  const movingRangeArray = addEmptyValues(movingRange, userEnteredData);

  const cumulAvg = calculateCumulAvg(userEnteredValues);
  const cumulAvgArray = addEmptyValues(cumulAvg, userEnteredData);

  const cumulMR = calculateCumulativeAvgMovingRange(userEnteredValues);
  const cumulMRArray = addEmptyValues(cumulMR, userEnteredData);

  const cumulAvgEstStdDev =
    calculateCumulativeAvgEstimatedStdDev(userEnteredValues);
  const cumulAvgEstStdDevArray = addEmptyValues(
    cumulAvgEstStdDev,
    userEnteredData
  );

  const xUcl = calculateXUCLIndividualChart(userEnteredValues, lockLimit);
  const xUclArray = addEmptyValues(xUcl, userEnteredData);

  const xCl = calculateXCL(userEnteredValues, lockLimit);
  const xClArray = addEmptyValues(xCl, userEnteredData);

  const xLcl = calculateXLCL(userEnteredValues, lockLimit);
  const xLclArray = addEmptyValues(xLcl, userEnteredData);

  const mrUcl = calculateMRUCLArray(userEnteredValues, lockLimit);
  const mrUclArray = addEmptyValues(mrUcl, userEnteredData);

  const mrCl = calculateMRCLArray(userEnteredValues, lockLimit);
  const mrClArray = addEmptyValues(mrCl, userEnteredData);

  const cpl = calculateCPLArray(userEnteredValues, lsl);
  const cplArray = addEmptyValues(cpl, userEnteredData);

  const cpu = calculateCPUArray(userEnteredValues, usl);
  const cpuArray = addEmptyValues(cpu, userEnteredData);

  const cpk = calculateCpkArray(userEnteredValues, usl, lsl);
  const cpkArray = addEmptyValues(cpk, userEnteredData);

  const avgCpk = averageCPK(userEnteredValues, usl, lsl);
  const avgCpkArray = addEmptyValues(avgCpk, userEnteredData);

  const sampleStdDevValue = sampleStdDev(userEnteredValues);
  const sampleStdDevArray = addEmptyValues(sampleStdDevValue, userEnteredData);

  const ppl = calculatePpl(userEnteredValues, lsl);
  const pplArray = addEmptyValues(ppl, userEnteredData);

  const ppu = calculatePpu(userEnteredValues, usl);
  const ppuArray = addEmptyValues(ppu, userEnteredData);

  const ppk = calculatePpk(userEnteredValues, usl, lsl);
  const ppkArray = addEmptyValues(ppk, userEnteredData);

  const avgPpk = calculateAvgPpk(userEnteredValues, usl, lsl);
  const avgPpkArray = addEmptyValues(avgPpk, userEnteredData);

  const cp = calculateCp(userEnteredValues, usl, lsl);
  const cpArray = addEmptyValues(cp, userEnteredData);

  const pp = calculatePp(userEnteredValues, usl, lsl);
  const ppArray = addEmptyValues(pp, userEnteredData);

  // Map with table array
  let userEditedTableData = [];
  for (let row = 0; row < userEnteredData.length; row++) {
    userEditedTableData.push([
      userEnteredData[row][0],
      userEnteredData[row][1],
      userEnteredData[row][2],
      isNaN(movingRangeArray[row]) ? "" : movingRangeArray[row],
      userEnteredData[row][3],
      isNaN(cumulAvgArray[row]) ? "" : cumulAvgArray[row],
      isNaN(cumulMRArray[row]) ? "" : cumulMRArray[row],
      isNaN(cumulAvgEstStdDevArray[row]) ? "" : cumulAvgEstStdDevArray[row],
      isNaN(xUclArray[row]) ? "" : xUclArray[row],
      isNaN(xClArray[row]) ? "" : xClArray[row],
      isNaN(xLclArray[row]) ? "" : xLclArray[row],
      isNaN(mrUclArray[row]) ? "" : mrUclArray[row],
      isNaN(mrClArray[row]) ? "" : mrClArray[row],
      isNaN(cplArray[row]) ? "" : cplArray[row],
      isNaN(cpuArray[row]) ? "" : cpuArray[row],
      isNaN(cpkArray[row]) ? "" : cpkArray[row],
      isNaN(avgCpkArray[row]) ? "" : avgCpkArray[row],
      isNaN(sampleStdDevArray[row]) ? "" : sampleStdDevArray[row],
      isNaN(pplArray[row]) ? "" : pplArray[row],
      isNaN(ppuArray[row]) ? "" : ppuArray[row],
      isNaN(ppkArray[row]) ? "" : ppkArray[row],
      isNaN(avgPpkArray[row]) ? "" : avgPpkArray[row],
      isNaN(cpArray[row]) ? "" : cpArray[row],
      isNaN(ppArray[row]) ? "" : ppArray[row],
    ]);
  }
  return userEditedTableData;
};

// Create Table data for spreadsheet
export const createTableData = (
  incomingData,
  columnHeaders,
  displayHeaders
) => {
  let withoutEmptyRow = [];
  const removeLastEmptyRow = (dataArray) => {
    if (
      dataArray.length === 0 ||
      !isEmptyRow(dataArray[dataArray.length - 1])
    ) {
      return dataArray;
    } else {
      dataArray.pop();
      return removeLastEmptyRow(dataArray);
    }
  };
  if (incomingData.length !== 0) {
    withoutEmptyRow = removeLastEmptyRow([...incomingData]);
  }
  const tableData = withoutEmptyRow.map((row) => {
    let rowData = [];
    for (let cell = 0; cell < displayHeaders.length; cell++) {
      if (cell < 3) {
        rowData.push({
          value: row[columnHeaders.indexOf(displayHeaders[cell])],
        });
      } else if (cell === 4) {
        rowData.push({
          value: row[columnHeaders.indexOf(displayHeaders[cell])],
        });
      } else {
        rowData.push({
          value: row[columnHeaders.indexOf(displayHeaders[cell])],
          readOnly: true,
        });
      }
    }
    return rowData;
  });
  for (let i = 0; i < 50; i++) {
    tableData.push([
      {
        value: "",
      },
      {
        value: "",
      },
      {
        value: "",
      },
      {
        value: "",
      },
    ]);
  }
  return tableData;
};

// Check is empty row
export const isEmptyRow = (row) => {
  return row.every((element) => element === "");
};

// Get value without null
export const getValuesWithoutNull = (userEnteredData) => {
  const values = userEnteredData
    .map((row) => {
      const cellValue = parseFloat(row[2]);
      return !isNaN(cellValue) ? cellValue : undefined;
    })
    .filter((value) => value !== undefined);
  return values;
};

export const addNewRow = (data, selectedRow, place) => {
  const newRow = ["", "", "", ""];
  if (place === "end") {
    data.push(newRow);
  } else if (place === "above") {
    data.splice(selectedRow, 0, newRow);
  } else if (place === "below") {
    data.splice(selectedRow + 1, 0, newRow);
  }
  return data;
};

export const removeRow = (data, selectedRow) => {
  data.splice(selectedRow, 1);
  return data;
};
